import * as $ from 'jquery';
import * as helper from './helpers';
import { Access } from './access';

interface IloginResponse {
    userId: string;
    isLoggedIn: boolean;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    hasAccess: boolean;
}

export class Login {
    init(): void {
        $('[data-authentication="login"]').on('click', (event: JQuery.ClickEvent) => {
            event.preventDefault();
            this.triggerLogin();
        });

        $('[data-authentication="register"]').on('click', (event: JQuery.ClickEvent) => {
            event.preventDefault();
            this.triggerLogin("register=true");
        });

        $('[data-authentication="logout"]').on('click', (event: JQuery.ClickEvent) => {
            event.preventDefault();
            this.triggerLogout();
        });

        this.isLoggedIn();
    }

    triggerLogin(param = ""): void {
        let returnUrl: string = location.pathname + location.search;

        if (helper.isStringNullOrEmpty(returnUrl)) {
            returnUrl = "/";
        }

        if (!helper.isStringNullOrEmpty(param)) {
            returnUrl += "&" + param;
        }

        window.location.href = "/account/login?returnUrl=" + returnUrl;
    }

    triggerLogout(): void {
        let returnUrl: string = location.pathname + location.search;

        // check redirecturl override value
        const returnUrlOverrideElement: JQuery<Element> = $("#LogoutRedirectUrlOverride");
        if (!helper.isObjectNullOrEmpty(returnUrlOverrideElement)) {
            const returnUrlOverrideValue: string = returnUrlOverrideElement.val() as string;

            if (!helper.isStringNullOrEmpty(returnUrlOverrideValue)) {
                returnUrl = returnUrlOverrideValue;
            }
        }

        if (helper.isStringNullOrEmpty(returnUrl)) {
            returnUrl = "/";
        }

        window.location.href = "/account/logout?returnUrl=" + returnUrl;
    }

    private isLoggedIn(): void {
        $.ajax({
            type: "GET",
            url: "/api/authentication/isloggedin/",
            cache: false,
            success: (response: IloginResponse): void => {
                if (response.isLoggedIn) {
                    window.currentUser = response;

                    this.loginCompleted();

                    Access.hasAccess({
                        callback: () => {
                            // TODO: callback stuff...
                        }, context: this
                    });
                } else {
                    this.loginCompleted();
                }
            },
            error: (xhr: JQueryXHR, ajaxOptions: string, thrownError: string): void => {
                console.error(JSON.stringify(xhr));
                this.loginCompleted();
            }
        });
    }

    /**
     * Login event completed and userdata is set.
     * Marks page_initialized and triggers all callback to be completed after login
     * */
    loginCompleted(): void {
        // set flag indicating the request is done
        window.page_initialized = true;

        // if callbacks are set... call them.
        // first capture push function, so new calls are executed immediately
        window.page_initializedCallback.push = function (c: ICallback): number {
            if (typeof c.callback === "function") {
                //console.log("Calling " + c.callback);
                c.callback.call(c.context);
            }
            return 0;
        }

        // then empty the existing queue of callbacks already added
        window.page_initializedCallback.forEach((v, i, a) => {
            window.page_initializedCallback.push(v);
        })
    }

    /**
     * Refreshes window.currentUser with logged in user
     * */
    static refreshCurrentUser(callback: any): void {
        $.ajax({
            type: "GET",
            url: "/api/Authentication/IsLoggedIn/",
            cache: false,
            success: (response: IloginResponse): void => {
                if (response.isLoggedIn) {
                    window.currentUser = response;
                    if (callback)
                        callback();
                }
            }
        });
    }
}