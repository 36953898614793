import * as $ from 'jquery';

export class Access {
    /**
     * Checks if the current user have access and sets hasAccess on window.currentUser.
     * @param callback
     */
    static hasAccess(callback: ICallback): void  {
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: '/api/access/hasaccess',
            success: (access: boolean) => {
                window.currentUser.hasAccess = access;

                if (callback !== undefined) {
                    callback.callback.call(callback.context);
                }
            }
        });
    }
}