import { create } from "@lottiefiles/lottie-interactivity";
import "@lottiefiles/lottie-player";

export class SponsorScroll {
    static init() {
        if(document.getElementById('sponsor-element'))
        {
            create({
                player: '#sponsor-player',
                mode: 'scroll',
                container: "#sponsor-element",
                actions: [
                    {
                        visibility: [-0.8, 1.0],
                        type: 'seek',
                        frames: [0, 120],
                    },
                ]
            });
        }
    }
}