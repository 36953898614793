import * as $ from 'jquery';
import * as helper from './helpers';
import jqueryValidation from 'jquery-validation'
import 'jquery-validation-unobtrusive'

export class ContactForm {
    static formSelector: string = '#contact-form';
    static formValidator: JQueryValidation.Validator;
    static sendingEmailInProgress: boolean = false;

    static init(): void {
        // set validator object
        this.formValidator = $(this.formSelector).validate();

        let contactFormElement: JQuery<Element> = $(".form-element");

        if (!helper.isNullOrEmpty(contactFormElement)) {
            contactFormElement.on("click", "button", (event: JQueryEventObject) => this.handleSubmit(event));
        }
    }

    /**
     * handle form submit event
     * @param event the event
     */
    static handleSubmit(event: JQueryEventObject): void {
        let _that: any = this;

        // check if form is valid
        if (this.formValidator.checkForm()) {
            // submit form
            event.preventDefault();
            this.setSendingEmailInProgress(true);
            $.ajax({
                type: "POST",
                url: "/Home/SubmitContactForm",
                cache: false,
                async: false,
                dataType: "json",
                data: $(_that.formSelector).serialize(),
            }).done((response: string): void => {
                if (helper.isStringNullOrEmpty(response)) {
                    $(".message").html("Anmodning indsendt");
                    $("#Inquiry").val("");
                    $("#InquirerEmail").val("");
                }
                else {
                    $(".message").html(response);
                }
            }).fail((xhr: JQueryXHR, ajaxOptions: string, thrownError: string): void => {
                $(".message").html("Der opstod en fejl");
            }).always(() => {
                _that.setSendingEmailInProgress(false);
            });
        }
    }

    private static setSendingEmailInProgress(inProgress: boolean) {
        this.sendingEmailInProgress = inProgress;

        if (inProgress) {
            this.disableSubmitBtn();
        }
        else {
            this.enableSubmitBtn();
        }
    }

    private static enableSubmitBtn(): void {
        $(this.formSelector + " button[type=submit]").removeAttr("disabled");
    }

    private static disableSubmitBtn(): void {
        $(this.formSelector + " button[type=submit]").attr("disabled", "disabled");
    }
}