import * as $ from 'jquery';
import 'bootstrap';
import { AppStart } from './appstart'
import { Menu } from './menu'
import { Login } from './login'
import { ContactForm } from './contactform';
import { CookieConsent } from "./cookieconsent";
import { Carousel } from "./carousel";
import { KeyTakeaways } from './key-takeaways';
import { ShareUrl } from './shareurl';
import { CoffeeBrief } from './coffeebrief';
import { CurrentNews } from './currentnews';
import { PartnerNewsTicker } from './partner-news-ticker';
import { SubmitPartnerNews } from './submitpartnernews';
import { SponsorScroll } from './article-sponsor-scroll';

/* Global inits */
$(function () {
    console.debug('Initializing "App"');

    AppStart.init();
    CookieConsent.init();
    Menu.init();
    Login.prototype.init();
    ContactForm.init();
    Carousel.init();
    KeyTakeaways.init();
    ShareUrl.init();
    CoffeeBrief.InitFrontpage();
    CurrentNews.InitFrontpage();
    PartnerNewsTicker.init();
    SubmitPartnerNews.init();
    SponsorScroll.init();
});
