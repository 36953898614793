import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import jqueryValidation from 'jquery-validation'
import 'jquery-validation-unobtrusive'
import 'jquery-validation/dist/localization/messages_da'

export class SubmitPartnerNews {
    static formSelector: string = '#partner-news-form';
    static submitPartnerNewsModal: bootstrap.Modal;

    static init(): void {
        $("#open-partner-news-submit-modal").on("click", (event: JQuery.ClickEvent) => this.openSubmitPartnerNewsModal(event));
        $("#submit-partner-news-modal").on("submit", (event: JQuery.SubmitEvent) => this.submitPartnerNewsData(event));
        $("#partner-consent-checkbox").on("change", () => this.toggleFormStatus());

        var maxLength = 1500;
        $("#partner-news-text-length").html('0 / ' + maxLength);
        $("#partner-news-text").on("keyup", () => this.updateLengthCount(maxLength))
    }

    // Toggles the submit button status on modal depending on if checkmark is set
    static toggleFormStatus() {
        let form = $(this.formSelector);
        let submitButton = form.find('button[type=submit]');

        var isPartnerCheckboxChecked = $("#partner-consent-checkbox").is(':checked');
        if (!isPartnerCheckboxChecked) {
            submitButton.attr('disabled', 'disabled');
        }
        else {
            submitButton.removeAttr('disabled');
        }
    }

    static updateLengthCount(maxLength) {
        var text = $("#partner-news-text").val() as string;
        $('#partner-news-text-length').html(text.length + ' / ' + maxLength);
    }

    /**
     * Open modal with form to submit partner news
     * @param event the click event
     */
    static openSubmitPartnerNewsModal(event: JQuery.ClickEvent): void {
        $("#submit-partner-news-header-initial-setup").show();
        $("#submit-partner-news-header-success-setup").hide();
        $("#submit-partner-news-initial-setup").show();
        $("#submit-partner-news-success-setup").hide();
        this.submitPartnerNewsModal = new bootstrap.Modal(document.getElementById('submit-partner-news-modal'));
        this.toggleFormStatus();
        this.submitPartnerNewsModal.show();
    }

    static async submitPartnerNewsData(event: JQuery.SubmitEvent): Promise<void> {
        event.preventDefault();

        let form = $(this.formSelector);
        if (!form) {
            return;
        }

        let formValidator = form.validate();

        if (!formValidator.checkForm()) {
            return;
        }

        if (!window.currentUser.isLoggedIn) {
            return;
        }

        let fieldset = form.find('fieldset');
        fieldset.attr('disabled', 'disabled');

        let spinner = form.find('[data-spinner]');
        spinner.removeClass('d-none');

        let data = {
            PartnerName: form.find("[name='PartnerName']").val(),
            PartnerEmail: form.find("[name='PartnerEmail']").val(),
            Title: form.find("[name='Title']").val(),
            Text: form.find("[name='Text']").val(),
        };

        let response = await fetch("/api/companies/submitpartnernews", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

        if (response.ok) {
            $("#submit-partner-news-initial-setup").hide();
            $("#submit-partner-news-header-initial-setup").hide();
            $("#submit-partner-news-errormessage").hide();
            $("#submit-partner-news-success-setup").show();
            $("#submit-partner-news-header-success-setup").show();
            form.trigger("reset");
        }
        else {
            $("#submit-partner-news-errormessage").show();
        }

        fieldset.removeAttr('disabled');
        spinner.addClass('d-none');
    }
}