/**
 * checks whether the string is null or empty
 * @param string the string
 */
export function isStringNullOrEmpty(obj: string): boolean {
    let res = true;

    if (obj !== null && obj !== undefined && obj.toString().length > 0) {
        res = false;
    }

    return res;
}

/**
 * checks whether the jquery element is null or empty
 * @param jQueryElement the jquery element
 */
export function isNullOrEmpty(jQueryElement: JQuery<Element>): boolean {
    var res = true;

    if (jQueryElement !== null && jQueryElement[0] !== undefined && jQueryElement.length > 0) {
        res = false;
    }

    return res;
}

/**
 * checks whether the object is null or empty
 * @param object the object
 */
export function isObjectNullOrEmpty(obj: any): boolean {
    var res = true;

    if (obj !== null && obj !== undefined) {
        res = false;
    }

    return res;
}

/**
 * extracts the value of the given parameter in the URL
 * @param parameterName name of the parameter
 */
export function extractUrlParameter(parameterName: string): string {
    var sPageurl = window.location.search.substring(1),
        sUrlVariables = sPageurl.split('&'),
        result = "";

    sUrlVariables.forEach((item: string) => {
        var param = item.split('=');
        if (param[0] == parameterName) {
            result = param[1];
        }
    });
    return result;
}