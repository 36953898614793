import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import * as helper from './helpers';

export class ShareUrl {

    static init(): void {
        $(".share-buttons, .social-links").on("click", ".facebook,[data-share-method=facebook]", (event: JQueryEventObject) => this.shareViaFacebook(event));
        $(".share-buttons, .social-links").on("click", ".linkedin,[data-share-method=linkedin]", (event: JQueryEventObject) => this.shareViaLinkedin(event));
        $(".share-buttons, .social-links").on("click", ".copylink,[data-share-method=clipboard]", (event: JQueryEventObject) => this.openShareLinkModal(event));
        $("#share-element").on("click", (event: JQueryEventObject) => this.openShareLinkModal(event));
        $("button[data-copy-btn]").on('click', (event: JQueryEventObject) => this.copyUrlToClipboard(event));
    }

    /**
     * Share article link via Facebook
     * @param event The click event
     * @param generateToken Generate share token if true
     */
    static shareViaFacebook(event: JQueryEventObject): void {
        event.preventDefault();
        let url: string = escape(window.location.href);
        let generateToken: string = $(event.currentTarget).data("generatetoken");

        if (generateToken === "True") {
            url += "&generateToken=true";
        }

        // Set title
        let title: string = document.title;

        if (!helper.isStringNullOrEmpty(title)) {
            url += "&title=" + title;
        }

        window.open("/api/share/ShareViaFacebook?url=" + url);
    }

    /**
     * Share article link via Linkedin
     * @param event The click event
     * @param generateToken Generate share token if true
     */
    static shareViaLinkedin(event: JQueryEventObject): void {
        event.preventDefault();
        let url: string = escape(window.location.href);
        let generateToken: string = $(event.currentTarget).data("generatetoken");

        if (generateToken === "True") {
            url += "&generateToken=true";
        }

        window.open("/api/share/ShareViaLinkedin?url=" + url);
    }

    /**
     * Open modal with option to share article link via copy to clipboard
     * @param event the click event
     */
    static openShareLinkModal(event: JQueryEventObject): void {
        event.preventDefault();
        let url: string = window.location.href;
        let generateToken: string = $(event.currentTarget).data("generatetoken");
        let shareLinkId: string = event.currentTarget.id;
        let shareUrl: string = "";

        if (generateToken === "True") {
            url += "&generateToken=true";
        }

        $.ajax({
            type: "GET",
            url: "/api/share/shareviacopylink?url=" + url,
            cache: false,
            async: false,
            contentType: "application/json"
        }).done(function (data) {
            if (data.success) {
                shareUrl = data.value;
            }
        });

        $('#' + shareLinkId + '-modal').appendTo('body'); //Move modal out of parent elements to fix position issues
        document.getElementById(shareLinkId + "-input").setAttribute("value", shareUrl);
        let myModal = new bootstrap.Modal(document.getElementById(shareLinkId + '-modal'));
        myModal.show();
    }

    /**
     * Copy the url from the modal input field to clipboard
     * @param event the click event
     */
    static copyUrlToClipboard(event: JQueryEventObject): void {
        var button = event.currentTarget as HTMLElement;
        let shareLinkId: string = button.dataset.shareId;
        var shareUrl = document.getElementById(shareLinkId + "-input").getAttribute("value");
        navigator.clipboard.writeText(shareUrl);

        button.classList.remove("btn-primary");
        button.classList.add("btn-success");
        button.innerHTML = "&#10004; Link kopieret";
        (document.activeElement as HTMLElement).blur();
    }
}