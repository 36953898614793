export class PartnerNewsTicker {
    private static interval = 3000;
    private static timer: number;

    static init(): void {
        console.debug('Initializing PartnerNewsTicker');

        const ticker = document.getElementById('ParterNewsTicker');
        if (!ticker) {
            return;
        }

        this.timer = this.startTimer(ticker);

        ticker.addEventListener('mouseenter', () => {
            clearInterval(this.timer);
        });

        ticker.addEventListener('mouseleave', () => {
            this.timer = this.startTimer(ticker);
        });
    }

    private static startTimer(ticker: HTMLElement): number {
        return window.setInterval(function (list: HTMLElement) {
            let firstElement = list.removeChild(list.firstElementChild);
            list.appendChild(firstElement);
        }, this.interval, ticker);
    }
}
